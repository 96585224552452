import axios from 'axios';
import qs from 'qs';
import firebase from 'firebase/app';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

export const appName = ` | ${process.env.REACT_APP_NAME}`;

export const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_API_URL;
// instance.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

instance.interceptors.request.use(
  async (config) => {
    const token = await firebase.auth().currentUser.getIdToken();
    if (!(config.data instanceof FormData) && typeof config.data !== 'string')
      config.data = qs.stringify(config.data, { arrayFormat: 'comma' });
    if (token) config.headers.Authorization = token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response)
      if (!navigator.onLine) {
        const { enqueueSnackbar } = useSnackbar();
        enqueueSnackbar('You are offline. Please check Internet Connection.', {
          variant: 'error'
        });
        return Promise.reject(error);
      } else {
        const { enqueueSnackbar } = useSnackbar();
        enqueueSnackbar('Your website API is not working at the moment.', {
          variant: 'error'
        });
        return Promise.reject(error);
      }

    return Promise.reject(error);
  }
);

export const instance2 = axios.create();
let appSecretKey = '';
export const setAppSecretKey = (key) => {
  appSecretKey = key;
};
// instance2.defaults.baseURL = 'https://site.hopwebsite.com/coreapi/';
instance2.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

instance2.interceptors.request.use(
  (config) => {
    if (config.headers.post['Content-Type'] === 'multipart/form-data') {
      instance2.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    } else {
      if (typeof config.data !== 'string') config.data = qs.stringify(config.data, { arrayFormat: 'comma' });
      config.data += (config.data.length > 0 ? '&_token=' : '_token=') + appSecretKey;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance2.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response)
      if (!navigator.onLine) {
        const { enqueueSnackbar } = useSnackbar();
        enqueueSnackbar('You are offline. Please check Internet Connection.', {
          variant: 'error'
        });
        return Promise.reject(error);
      } else {
        const { enqueueSnackbar } = useSnackbar();
        enqueueSnackbar('Your website API is not working at the moment.', {
          variant: 'error'
        });
        return Promise.reject(error);
      }
    return Promise.reject(error);
  }
);
