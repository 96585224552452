import { Icon } from '@iconify/react';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import globeFill from '@iconify/icons-eva/globe-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { ListItem, Box, Typography, ListItemText, OutlinedInput, InputAdornment, Button } from '@material-ui/core';
import { experimentalStyled as styled, alpha } from '@material-ui/core/styles';
// hooks
import useLocalStorage from 'hooks/useLocalStorage';
// components
import Scrollbar from 'components/Scrollbar';
import MenuPopover from 'components/MenuPopover';
import useAuth from 'hooks/useAuth';
// redux
import { useDispatch, useSelector } from 'redux/store';
import { fetchAllWebsites } from 'app/website/slices/websiteSlice';

import { instance2, setAppSecretKey } from 'utils/axios';
import { history } from 'hooks/history';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 54;
const PADDING_ITEM = 2.5;

export default function SiteSelector() {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState('');

  const [selectedSite, setSelectedSite] = useLocalStorage('selectedSite', {});

  const { allwebsites } = useSelector((state) => state.website);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredSites = allwebsites.filter(({ website_domain }) => website_domain.includes(filterName));

  useEffect(() => {
    if (allwebsites.length > 0 && pathname.indexOf('/site/') === -1) {
      const { website_domain, website_url, website_site_selector_code, website_app_secret_key } = allwebsites[0];
      setSelectedSite({
        domain: website_domain,
        url: website_url,
        siteSelectorCode: website_site_selector_code,
        appSecretKey: website_app_secret_key
      });
    } else if (pathname.indexOf('/site/') !== -1) {
      const trimmedUrls = pathname.split('/');

      // eslint-disable-next-line
      let website = allwebsites.find(function (el) {
        return el.website_domain === trimmedUrls[trimmedUrls.indexOf('site') + 1];
      });
      if (website) {
        const { website_domain, website_url, website_site_selector_code, website_app_secret_key } = website;
        setSelectedSite({
          domain: website_domain,
          url: website_url,
          siteSelectorCode: website_site_selector_code,
          appSecretKey: website_app_secret_key
        });
      }
    }
  }, [allwebsites]);

  useEffect(() => {
    instance2.defaults.baseURL = `https://${selectedSite.domain}/coreapi`;
    setAppSecretKey(selectedSite.appSecretKey);

    if (pathname.indexOf('/site/') !== -1) {
      const trimmedUrls = pathname.split('/');
      trimmedUrls[trimmedUrls.indexOf('site') + 1] = selectedSite.domain;
      const urlString = trimmedUrls.join('/');
      history.replace(urlString);
    }
  }, [selectedSite]);

  useEffect(() => {
    dispatch(fetchAllWebsites());
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    notched: false,
    height: '40px',
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    })
  }));

  return (
    <>
      <Button fullWidth ref={anchorRef} onClick={handleOpen} style={{ textTransform: 'none' }}>
        <Icon icon={globeFill} width={20} height={20} />
        &nbsp;
        <Typography style={{ width: '170px', textAlign: 'left' }}>
          {selectedSite.domain?.length > 20 ? `${selectedSite.domain.substr(0, 20 - 1)}...` : selectedSite.domain}
        </Typography>
        &nbsp;&nbsp;
        <Icon icon={arrowIosDownwardFill} width={20} height={20} />
      </Button>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 280 }}>
        <SearchStyle
          autoFocus
          sx={{ m: 1.2 }}
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Search Here..."
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />

        <Scrollbar sx={{ height: ITEM_HEIGHT * 8 }}>
          {filteredSites.map((site) => {
            const { id, website_domain, website_url, website_site_selector_code, website_app_secret_key } = site;

            return (
              <ListItem
                onClick={() => {
                  setSelectedSite({
                    domain: website_domain,
                    url: website_url,
                    siteSelectorCode: website_site_selector_code,
                    appSecretKey: website_app_secret_key
                  });
                  handleClose();
                }}
                button
                disableGutters
                key={id}
                sx={{
                  px: PADDING_ITEM,
                  height: ITEM_HEIGHT,
                  ...(selectedSite.domain === website_domain && {
                    bgcolor: 'action.selected'
                  })
                }}
              >
                <ListItemText
                  primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                  primary={website_domain}
                />
              </ListItem>
            );
          })}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
