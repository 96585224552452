import { lazy } from 'react';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import SvgIconStyle from 'components/SvgIconStyle';
import Loadable from './Loadable';
// Dashboard
const GeneralApp = Loadable(lazy(() => import('pages/dashboard/GeneralApp')));

const UserProfile = Loadable(lazy(() => import('pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('pages/dashboard/UserCreate')));

const WebsiteList = Loadable(lazy(() => import('app/website/views/WebsiteList')));
const OneWebsite = Loadable(lazy(() => import('app/website/views/OneWebsite')));

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban')
};

// Dashboard Routes
const ThemeRoutes = [
  {
    path: '/manage',
    url: '/manage',
    name: 'Manage',
    title: 'Manage',
    id: 'dashboard',
    icon: PeopleAltIcon,
    child: [
      {
        path: '/dashboard',
        url: '/manage/dashboard',
        name: 'Dashboard',
        title: 'Dashboard',
        element: <GeneralApp />,
        icon: ICONS.dashboard
      },
      {
        path: '/user',
        url: 'manage/user',
        title: 'user',
        icon: ICONS.user,
        subchild: [
          {
            path: '/:name/edit',
            url: 'manage/user/:name/edit',
            title: '/:name/edit',
            hide: true,
            element: <UserCreate />
          },
          {
            path: 'account',
            url: 'manage/user/account',
            title: 'account',
            element: <UserAccount />
          }
        ]
      },
      {
        path: '/websites',
        url: 'manage/websites',
        title: 'websites',
        icon: ICONS.user,
        subchild: [
          {
            path: 'list',
            url: 'manage/websites/list',
            title: 'All Websites',
            element: <WebsiteList />
          },
          {
            path: 'new',
            url: 'manage/websites/new',
            title: 'Add Website',
            element: <OneWebsite />
          },
          {
            path: '/:id/edit',
            url: 'manage/websites/:id/edit',
            title: '/:id/edit',
            hide: true,
            element: <OneWebsite />
          }
        ]
      }
    ]
  },
  {
    path: '/site/:sitecode/cms',
    url: '/site/:sitecode/cms',
    name: 'CMS',
    title: 'CMS',
    id: 'CMS',
    icon: SettingsIcon,
    child: [
      {
        path: 'dashboard',
        url: 'site/:sitecode/cms/dashboard',
        title: 'dashboard',
        icon: ICONS.user,
        element: <UserCreate />
      },
      {
        path: 'post',
        url: 'site/:sitecode/cms/post',
        title: 'post',
        icon: ICONS.user,
        subchild: [
          {
            path: 'list',
            url: 'site/:sitecode/cms/post/list',
            title: 'list',
            element: <UserAccount />
          }
        ]
      }
    ]
  },
  {
    path: '/site/:sitecode/settings',
    url: '/site/:sitecode/settings',
    name: 'Settings',
    title: 'Settings',
    id: 'Settings',
    icon: SettingsIcon,
    child: [
      {
        path: 'general',
        url: 'site/:sitecode/settings/general',
        title: 'general',
        icon: ICONS.user,
        element: <UserCreate />
      }
    ]
  }
];
export default ThemeRoutes;
